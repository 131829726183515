import { Injectable } from '@angular/core';
import { PrinterService } from './printer.service';
import { SostojbaService } from '../core/shared/sostojba.service';
import { INivelacija } from '../models/prodavnica/nivelacija';

@Injectable()
export class NivelacijaPrinterService {

    constructor(public sostojbaService: SostojbaService,
        public printer: PrinterService) {
    }

    getNivelacija(model: INivelacija) {
        const data = this.printer.getFinansiskiPregled();
        data.content[0].table = {
            headerRows: 2,
            widths: ['auto', '*', 'auto', 'auto', 'auto'],
            body: [
                [
                    this.printer.tableHeaderCell([
                            this.printer.tableHeaderTitle(`Нивелација бр.${model.detali.brojNaDokument}`),
                            this.printer.tableHeaderSubtitle(`од продавница ${model.detali.prodavnica}`)
                        ],
                        5),
                    '',
                    '',
                    '',
                    ''
                ],
                [
                    this.printer.headerCellForText('Р.бр.'),
                    this.printer.headerCellForText('Назив'),
                    this.printer.headerCellForNumber('Количина'),
                    this.printer.headerCellForNumber('Стара цена'),
                    this.printer.headerCellForNumber('Нова цена')
                ]
            ],
            dontBreakRows: true
        };
        let i = 1;
        model.stavki.map(x => data.content[0].table.body.push([
            this.printer.regularCellText(`${i++}.`, 'right'),
            this.printer.regularCellText(x.artiklNaziv),
            this.printer.regularCellNumber(x.kolicina, 0),
            this.printer.regularCellNumber(x.staraCena, 0),
            this.printer.regularCellNumber(x.novaCena, 0)
        ]));
        data.content[0].table.body.push([
            this.printer.footerCellText('Вкупно:', 2), '', this.printer.footerCellNumber(model.vkupnoKolicina, 0), '',
            ''
        ]);
        return data;
    }

}
