<div [formGroup]="parent"
     class="mdc-layout-grid__inner">
    <mat-form-field class="mdc-layout-grid__cell--span-12">
        <mat-select [formControlName]="controlName"
                    [placeholder]="placeholderText">
            <mat-option *ngFor="let sostav of sostojbaService.sostojba.sostavi"
                        [value]="sostav.id">
                {{ sostav.opis }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="parent.get(controlName).hasError('required')">
            Составот е задолжителен
        </mat-error>
    </mat-form-field>
</div>
