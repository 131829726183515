import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-temperatura-input',
    templateUrl: './temperatura-input.component.html'
})
export class TemperaturaInputComponent {

    @Input()
    parent: UntypedFormGroup;

    @Input()
    controlName: string;

    sifri = [{ value: 0, tekst: '30 C' }, { value: 1, tekst: '40 C' }];

}
