import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, } from 'rxjs/operators';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent {

    breadcrumbs: Array<Object>;

    constructor(private router: Router, private route: ActivatedRoute) {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            this.breadcrumbs = [];
            let currentRoute = this.route.root,
                url = '';
            do {
                const childrenRoutes = currentRoute.children;
                currentRoute = null;
                childrenRoutes.forEach(r => {
                    if (r.outlet === 'primary') {
                        const routeSnapshot = r.snapshot;
                        url += `/${routeSnapshot.url.map(segment => segment.path).join('/')}`;
                        if (r.snapshot.data.hasOwnProperty('title')) {
                            this.breadcrumbs.push({
                                label: r.snapshot.data,
                                url: url
                            });
                        }
                        currentRoute = r;
                    }
                });
            } while (currentRoute);
        });
    }
}
