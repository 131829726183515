import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IKdfiKasa } from '../../models/prodavnica/kdfi';
import { ITrgovskaKnigaListaModel } from '../../models/prodavnica/trgovska-kniga';
import { IQuickViewProdavnica } from '../../models/prodavnica/quick-view';
import { QuickViewPostModel } from '../../models/prodavnica/quick-view';
import { IProverkaNaArtikl } from '../../models/prodavnica/proverka-na-artikl';
import { PregledPoPeriodPostModel } from '../../models/prodavnica/pregled-po-period';
import { IStavkaZaPregledPoPeriod } from '../../models/prodavnica/pregled-po-period';
import { PregledPoSaatiPostModel } from '../../models/prodavnica/pregled-po-saati';
import { IPregledPoSaatiProdavnica } from '../../models/prodavnica/pregled-po-saati';
import { IQuickViewZaliha } from '../../models/prodavnica/quick-view';
import { IPregledPoKategoriiLokacija } from '../../models/prodavnica/pregled-po-kategorii';
import { PregledPoKategoriiPostModel } from '../../models/prodavnica/pregled-po-kategorii';
import { ITarget } from '../../models/prodavnica/target';
import { IzvestajPostModel } from '../../models/prodavnica/izvestaj';
import { IIZvestajStavka } from '../../models/prodavnica/izvestaj';

@Injectable()
export class IzvestaiService {

    constructor(private http: HttpClient) {}

    getKdfi(mesec: string): Observable<IKdfiKasa[]> {
        return this.http.get<IKdfiKasa[]>(`/ProdavnicaApi/Izvestai/GetKdfi/${mesec}`);
    }

    getEt(): Observable<ITrgovskaKnigaListaModel[]> {
        return this.http.get<ITrgovskaKnigaListaModel[]>(`/ProdavnicaApi/Izvestai/GetEt`);
    }

    getQuickView(model: QuickViewPostModel): Observable<IQuickViewProdavnica> {
        return this.http.post<IQuickViewProdavnica>(`/ProdavnicaApi/Izvestai/GetQuickView`, model);
    }

    getProverka(barkod: string): Observable<IProverkaNaArtikl[]> {
        return this.http.get<IProverkaNaArtikl[]>(`/ProdavnicaApi/Izvestai/GetProverka/${barkod}`);
    }

    getPregeldPoPeriod(model: PregledPoPeriodPostModel): Observable<IStavkaZaPregledPoPeriod[]> {
        return this.http.post<IStavkaZaPregledPoPeriod[]>(`/ProdavnicaApi/Izvestai/GetPregledPoPeriod`, model);
    }

    getPregeldPoSaati(model: PregledPoSaatiPostModel): Observable<IPregledPoSaatiProdavnica> {
        return this.http.post<IPregledPoSaatiProdavnica>(`/ProdavnicaApi/Izvestai/GetPregledPoSaat`, model);
    }

    getZaliha(): Observable<IQuickViewZaliha[]> {
        return this.http.get<IQuickViewZaliha[]>(`/ProdavnicaApi/Izvestai/GetZaliha`);
    }

    getPregledPoKategorii(model: PregledPoKategoriiPostModel): Observable<IPregledPoKategoriiLokacija> {
        return this.http.post<IPregledPoKategoriiLokacija>(`/ProdavnicaApi/Izvestai/GetPregledPoKategorii`, model);
    }

    getTarget(model: QuickViewPostModel): Observable<ITarget> {
        return this.http.post<ITarget>(`/ProdavnicaApi/Target/Get`, model);
    }

    getIzvestaj(model: IzvestajPostModel): Observable<IIZvestajStavka> {
        return this.http.post<IIZvestajStavka>(`/ProdavnicaApi/Izvestai/Izvestaj`, model);
    }

}
