import { Injectable } from '@angular/core';
import { DecimalPipe, DatePipe } from '@angular/common';
import { SostojbaService } from '../core/shared/sostojba.service';

@Injectable()
export class PrinterService {
    private readonly pdfFonts: any;
    pdfMake: any;

    constructor(public decimalPipe: DecimalPipe,
        public datePipe: DatePipe,
        public sostojbaService: SostojbaService) {
        this.pdfMake = require('pdfmake/build/pdfmake.js');
        this.pdfFonts = require('pdfmake/build/vfs_fonts.js');
        this.pdfMake.vfs = this.pdfFonts.pdfMake.vfs;
    }

    // DOCUMENT DEFINITIONS

    getDocumentNoPn(ladnscape: boolean = false) {
        return {
            header: this.getHeader(),
            content: [] as any[],
            styles: {
                basicStyle: {
                    fontSize: 10,
                },
                cellHeader: {
                    bold: true,
                },
                tableHeader: {
                    fontSize: 13,
                    bold: true
                },
                tableSubheader: {
                    fontSize: 11,
                    bold: true
                },
                nazivNaFirma: {
                    fontSize: 22,
                    bold: true
                },
                smallStyle: {
                    fontSize: 8,
                }
            },
            pageOrientation: ladnscape ? 'landscape' : 'portrait',
            pageMargins: [20, 40]
        };
    }

    getDocument(ladnscape: boolean = false) {
        return {
            header: this.getHeader(),
            footer: this.getFooter(),
            content: [] as any[],
            styles: {
                basicStyle: {
                    fontSize: 10,
                },
                fakturaStyle: {
                    fontSize: 9,
                },
                cellHeader: {
                    bold: true,
                },
                tableHeader: {
                    fontSize: 13,
                    bold: true
                },
                tableSubheader: {
                    fontSize: 11,
                    bold: true
                },
                nazivNaFirma: {
                    fontSize: 22,
                    bold: true
                },
                smallStyle: {
                    fontSize: 8,
                }
            },
            pageOrientation: ladnscape ? 'landscape' : 'portrait',
            pageMargins: [20, 40]
        };
    }

    getFinansiskiPregled(ladnscape: boolean = false) {
        const data = this.getDocument(ladnscape);
        data.content = [
            {
                style: 'basicStyle',
                table: {
                    headerRows: 1,
                    widths: [] as any[],
                    body: [] as any[],
                    dontBreakRows: true
                },
                layout: 'lightHorizontalLines'
            }
        ];
        return data;
    }

    getMaterijalenPregled(ladnscape: boolean = false) {
        const data = this.getDocument(ladnscape);
        data.content = [
            {
                style: 'basicStyle',
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: [] as any[],
                    dontBreakRows: false
                },
                layout: 'noBorders'
            }
        ];
        return data;
    }

    getMainContent(pageBreak: boolean) {
        return {
            style: 'basicStyle',
            table: {
                headerRows: 1,
                widths: ['*'],
                body: [] as any[],
                dontBreakRows: false
            },
            layout: 'noBorders',
            pageBreak: pageBreak ? 'after' : ''
        };
    }

    getTableContent(headerRows: number, widths: string[], style: string = 'basicStyle') {
        return {
            style: style,
            table: {
                headerRows: headerRows,
                widths: widths,
                body: [] as any[],
                dontBreakRows: true
            },
            layout: 'lightHorizontalLines'
        };
    }

    getPoedinecnaPresmetkaDokument() {
        return {
            content: [] as any[],
            styles: {
                prvRed: {
                    fontSize: 16,
                    bold: true
                },
                globalniDetali: {
                    fontSize: 9
                },
                tretRedNaslovi: {
                    fontSize: 14,
                    bold: true,
                    alignment: 'center',
                }
            },
            pageMargins: [15, 30],
        };
    }

    // HEADER AND FOOTER
    getHeader() {
        return {
            text: this.sostojbaService.sostojba.stranka.kratokNaziv,
            margin: [15, 15]
        };
    }

    getFooter() {
        return (currentPage: number, pageCount: number) => ({
            style: 'basicStyle',
            stack: [{ text: currentPage + ' од ' + pageCount, alignment: 'right' }] as any[],
            margin: [15, 15]
        });
    }

    // HEADER CELLS
    tableHeaderCell(x: any[], columnSpan: number = 1, rowspan: number = 1, alignment: string = 'center') {
        return { stack: x, alignment: alignment, rowSpan: rowspan, colSpan: columnSpan };
    }

    tableHeaderTitle(x: string) { return { text: x, style: 'tableHeader' }; }

    tableHeaderSubtitle(x: string) { return { text: x, style: 'tableSubheader' }; }

    headerCellForText(x: string | null, colspan: number = 1, rowspan: number = 1, alignment: string = 'left') {
        return { text: x ? x : '', style: 'cellHeader', alignment: alignment, rowSpan: rowspan, colSpan: colspan };
    }

    headerCellForNumber(x: string | null, colspan: number = 1, rowspan: number = 1, alignment: string = 'right') {
        return { text: x ? x : '', style: 'cellHeader', alignment: alignment, rowSpan: rowspan, colSpan: colspan };
    }

    // REGULAR CELLS

    regularCellText(x: string | null, alignment: string = 'left', colspan: number = 1, rowspan: number = 1) {
        return { text: x ? x : '', style: '', alignment: alignment, rowSpan: rowspan, colSpan: colspan };
    }

    regularCellDate(x: string | Date, alignment: string = 'left', colspan: number = 1, rowspan: number = 1) {
        return {
            text: typeof x === 'string' ? this.formatDateFromForm(x) : this.formatDate(x),
            style: '',
            alignment: alignment,
            rowSpan: rowspan,
            colSpan: colspan
        };
    }

    regularCellNumber(x: number,
        decimali: number = 2,
        alignment: string = 'right',
        colspan: number = 1,
        rowspan: number = 1) {
        return {
            text: `${this.formatNumber(x, decimali)}`,
            style: '',
            alignment: alignment,
            rowSpan: rowspan,
            colSpan: colspan
        };
    }

    regularCellValutaNumber(x: number,
        valuta: string,
        decimali: number = 2,
        alignment: string = 'right',
        colspan: number = 1,
        rowspan: number = 1) {
        return {
            text: `${this.formatNumber(x, decimali)} ${valuta}`,
            style: '',
            alignment: alignment,
            rowSpan: rowspan,
            colSpan: colspan,
            noWrap: true
        };
    }

    regularCellPercent(x: number,
        decimali: number = 2,
        alignment: string = 'right',
        colspan: number = 1,
        rowspan: number = 1) {
        return {
            text: `${this.formatNumber(x, decimali)}%`,
            style: '',
            alignment: alignment,
            rowSpan: rowspan,
            colSpan: colspan
        };
    }

    // FOOTER CELLS

    footerCellText(x: string | null,
        columnSpan: number = 1,
        rowSpan: number = 1,
        alignment = 'right',
        style: string = 'cellHeader') {
        return { text: x ? x : '', style: style, alignment: alignment, rowSpan: rowSpan, colSpan: columnSpan };
    }

    footerCellNumber(x: number, decimali: number = 2) {
        return {
            text: `${this.formatNumber(x, decimali)}`,
            style: 'cellHeader',
            alignment: 'right',
            rowSpan: 1,
            colSpan: 1
        };
    }

    footerCellValutaNumber(x: number, valuta: string) {
        return {
            text: `${this.formatNumber(x)} ${valuta}`,
            style: 'cellHeader',
            alignment: 'right',
            rowSpan: 1,
            colSpan: 1,
            noWrap: true
        };
    }

    footerCellPercent(x: number,
        decimali: number = 2,
        alignment: string = 'right',
        colspan: number = 1,
        rowspan: number = 1) {
        return {
            text: `${this.formatNumber(x, decimali)}%`,
            style: 'cellHeader',
            alignment: alignment,
            rowSpan: rowspan,
            colSpan: colspan
        };
    }

    // HELPER FUNCTIONS

    formatNumber(x: number, decimali: number = 2): string {
        return this.decimalPipe.transform(x, `1.${decimali}-${decimali}`);
    }

    formatDate(x: Date): string { return this.datePipe.transform(x, 'dd.MM.yyyy'); }

    formatDateFromForm(x: string | null): string {
        return x ? this.formatDate(new Date(x)) : this.formatDate(new Date());
    }

    getArtiklZaPrint(id: any): string {
        const artikl = this.sostojbaService.sostojba.artikli.find(x => x.id === id);
        return artikl ? `${artikl.sifra} - ${artikl.naziv}` : '';
    }

    getMagacinZaPrint(id: any): string {
        const magacin = this.sostojbaService.sostojba.magacini.find(x => x.id === id);
        return magacin ? `${magacin.naziv} - ${magacin.opis}` : '';
    }

    getProdavnicaZaPrint(id: any): string {
        const prodavnica = this.sostojbaService.sostojba.prodavnici.find(x => x.id === id);
        return prodavnica ? `${prodavnica.naziv} - ${prodavnica.opis}` : '';
    }

}
