<div [formGroup]="parent"
     class="mdc-layout-grid__inner">
    <mat-form-field class="mdc-layout-grid__cell--span-12">
        <mat-select [formControlName]="controlName"
                    placeholder="Температура за перење">
            <mat-option *ngFor="let sifra of sifri "
                        [value]="sifra.value">
                {{ sifra.tekst }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
