import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IKalkulacija, IDetaliZaKalkulacija, IStavkaZaKalkulacija } from '../../models/prodavnica/kalkulacija';
import { BarkodPostModel } from '../../models/prodavnica/barkod-post';

@Injectable()
export class KalkulacijaService {

    constructor(private http: HttpClient) {}

    getDokument(id: string): Observable<IKalkulacija> {
        return this.http.get<IKalkulacija>(`/ProdavnicaApi/Kalkulacija/GetDokument/${id}`);
    }

    postDokument(id: number): Observable<IDetaliZaKalkulacija> {
        return this.http.get<IDetaliZaKalkulacija>(`/ProdavnicaApi/Kalkulacija/PostDokument/${id}`);
    }

    postBarkod(stavka: BarkodPostModel): Observable<IStavkaZaKalkulacija> {
        return this.http.post<IStavkaZaKalkulacija>(`/ProdavnicaApi/Kalkulacija/PostBarkod`, stavka);
    }

    postBarkodBroenje(stavka: BarkodPostModel): Observable<IStavkaZaKalkulacija> {
        return this.http.post<IStavkaZaKalkulacija>(`/ProdavnicaApi/Kalkulacija/PostBarkodBroenje`, stavka);
    }

    resetBarkodBroenje(id: number): Observable<IStavkaZaKalkulacija> {
        return this.http.get<IStavkaZaKalkulacija>(`/ProdavnicaApi/Kalkulacija/ResetBarkodBroenje/${id}`);
    }

    deleteStavka(id: number) { return this.http.get(`/ProdavnicaApi/Kalkulacija/DeleteStavka/${id}`); }

    zatvoriKalkulacija(id: number) { return this.http.get(`/ProdavnicaApi/Kalkulacija/Zatvori/${id}`); }

    azurirajKalkulacija(id: number) { return this.http.get(`/ProdavnicaApi/Kalkulacija/Azuriraj/${id}`); }

}
