import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISlikaResponse } from '../../models/prodavnica/artikl';
import { IVcitanArtikl } from '../../models/prodavnica/artikl';
import { VcitanArtiklPostModel } from '../../models/prodavnica/artikl';

@Injectable()
export class ArtiklService {

    constructor(public httpClient: HttpClient) {}

    editArtiklCheckLista(model: VcitanArtiklPostModel): Observable<IVcitanArtikl> {
        return this.httpClient.post<IVcitanArtikl>(`/ProdavnicaApi/Artikl/EditArtiklCheckLista`, model);
    }

    postSlika(id: number, files: FormData): Observable<HttpEvent<void>> {
        const req = new HttpRequest('POST', `/ProdavnicaApi/Artikl/PostSlika/${id}`, files, { reportProgress: true, });
        return this.httpClient.request<void>(req);
    }

    getSlika(id: number): Observable<ISlikaResponse> {
        return this.httpClient.get<ISlikaResponse>(`/ProdavnicaApi/Artikl/GetSlika/${id}`);
    }

}
