<mat-toolbar class="breadcrumb mat-elevation-z2">
    <ng-template [ngForOf]="breadcrumbs"
                 let-breadcrumb
                 let-first="first"
                 let-last="last"
                 ngFor>
        <button *ngIf="breadcrumb.label.title||breadcrumb.label.title&&last"
                [disabled]="last"
                [routerLink]="breadcrumb.url"
                color="primary"
                mat-button>
            {{breadcrumb.label.title}}
        </button>
        <mat-icon *ngIf="!last"
                  color="primary">
            navigate_next
        </mat-icon>
    </ng-template>
</mat-toolbar>
