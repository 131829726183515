<div [formGroup]="parent"
     class="mdc-layout-grid__inner">
    <mat-form-field class="mdc-layout-grid__cell--span-12">
        <mat-select [formControlName]="controlName"
                    [placeholder]="placeholderText">
            <mat-option (onSelectionChange)="onSelected($event,prodavnica);"
                        *ngFor="let prodavnica of sostojbaService.sostojba.prodavnici"
                        [value]="prodavnica.id">
                {{ prodavnica.naziv }} - {{ prodavnica.opis }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="parent.get(controlName).hasError('required')">
            {{ requiredText }}
        </mat-error>
    </mat-form-field>
</div>
