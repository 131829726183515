import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrinterService } from './printer.service';
import { KalkulacijaPrinterService } from './kalkulacija-printer .service';
import { PrenosnicaPrinterService } from './prenosnica-printer .service';
import { NivelacijaPrinterService } from './nivelacija-printer.service';
import { IzvestaiPrinterService } from './izvestai-printer.service';

@NgModule({
    imports: [CommonModule],
    providers: [
        PrinterService,
        KalkulacijaPrinterService,
        PrenosnicaPrinterService,
        NivelacijaPrinterService,
        IzvestaiPrinterService
    ]
})
export class PrinterModule {
}
