import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AccountService } from './core/shared/account.service';
import { MessageDialogService } from './core/shared/message-dialog.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

    constructor(public accountService: AccountService,
        public router: Router,
        public breakpointObserver: BreakpointObserver,
        public messageDialogService: MessageDialogService) {
        breakpointObserver.observe(['(max-width: 960px)'])
            .subscribe(result => {
                if (result.matches) {
                    this.accountService.phoneDisplay = true;
                }
            });
        breakpointObserver.observe(['(min-width: 960px)'])
            .subscribe(result => {
                if (result.matches) {
                    this.accountService.phoneDisplay = false;
                }
            });
    }

    logOut() {
        this.messageDialogService.showMessage('Дали сте сигурни дека сакате да се одјавите?', true)
            .subscribe((result: boolean) => {
                if (result) {
                    this.accountService.logout();
                    this.router.navigateByUrl('');
                }
            });
    }
}
