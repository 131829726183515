<div [ngClass]="accountService.activeTheme">
    <mat-toolbar class="mat-elevation-z6"
                 color="primary">
        <button mat-button
                routerLink="">
            АМБАР
        </button>
        <span class="spacer"></span>
        <div>
            <button [matMenuTriggerFor]="menu"
                    mat-icon-button>
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button *ngIf="accountService.loggedIn"
                        mat-menu-item
                        replace-url="true"
                        routerLink="/Home/Account">
                    <mat-icon color="primary">account_circle</mat-icon>
                    <span>Мој профил</span>
                </button>
                <button (click)="logOut();"
                        *ngIf="accountService.loggedIn"
                        mat-menu-item>
                    <mat-icon color="warn">power_settings_new</mat-icon>
                    <span>Одјави се</span>
                </button>
            </mat-menu>
        </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
</div>
