import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BarkodPostModel } from '../../models/prodavnica/barkod-post';
import { INivelacija, IStavkaZaNivelacija } from '../../models/prodavnica/nivelacija';

@Injectable()
export class NivelacijaService {

    constructor(private http: HttpClient) {}

    getDokument(id: string): Observable<INivelacija> {
        return this.http.get<INivelacija>(`/ProdavnicaApi/Nivelacija/GetDokument/${id}`);
    }

    postBarkodBroenje(stavka: BarkodPostModel): Observable<IStavkaZaNivelacija> {
        return this.http.post<IStavkaZaNivelacija>(`/ProdavnicaApi/Nivelacija/PostBarkodBroenje`, stavka);
    }

    resetBarkodBroenje(id: number, artiklId: number): Observable<IStavkaZaNivelacija> {
        return this.http.get<IStavkaZaNivelacija>(`/ProdavnicaApi/Nivelacija/ResetBarkodBroenje/${id}/${artiklId}`);
    }

    resetBarkodBroenjeStavka(stavkaId: number): Observable<IStavkaZaNivelacija> {
        return this.http.get<IStavkaZaNivelacija>(`/ProdavnicaApi/Nivelacija/ResetBarkodBroenjeStavka/${stavkaId}`);
    }

    azurirajNivelacija(id: number) { return this.http.get(`/ProdavnicaApi/Nivelacija/Azuriraj/${id}`); }

}
