import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { SostojbaService } from '../../../core/shared/sostojba.service';
import { IMagacin } from '../../../models/prodavnica/magacin';

@Component({
    selector: 'app-magacin-input',
    templateUrl: './magacin-input.component.html',
    styles: []
})
export class MagacinInputComponent implements OnInit {

    @Input()
    parent: UntypedFormGroup;

    @Input()
    controlName: string;

    @Input()
    prefiks = '';

    @Input()
    placeholderText = 'Магацин';

    @Input()
    requiredText = 'Магацинот е задолжителен';

    @Output()
    onSelect = new EventEmitter<any>();

    constructor(public sostojbaService: SostojbaService) {}

    ngOnInit(): void {}

    onSelected(event: MatOptionSelectionChange, magacin: IMagacin) {
        if (event.source.selected) {
            this.onSelect.emit(magacin);
        }
    }

}
