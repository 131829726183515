import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '../shared/account.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(public accountService: AccountService, public router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> |
                                                                           Promise<boolean | UrlTree> |
                                                                           boolean |
                                                                           UrlTree {
        if (this.accountService.korisnik) {
            return true;
        }
        this.router.navigateByUrl('');
        return false;
    }

}
