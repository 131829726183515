import { Injectable } from '@angular/core';
import { PrinterService } from './printer.service';
import { IKdfiKasa } from '../models/prodavnica/kdfi';
import { ITrgovskaKnigaListaModel } from '../models/prodavnica/trgovska-kniga';

@Injectable()
export class IzvestaiPrinterService {

    constructor(public printer: PrinterService) {}

    et(stranica: ITrgovskaKnigaListaModel) {
        const data = this.printer.getMaterijalenPregled();
        data.content[0] = {
            style: 'fakturaStyle',
            table: {
                widths: ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'],
                body: [
                    [
                        this.printer.tableHeaderCell([
                                this.printer.tableHeaderTitle(
                                    `Евиденција во трговија на мало за ${stranica.prodavnicaNaziv}`)
                            ],
                            7),
                        '',
                        '',
                        '',
                        '',
                        '',
                        ''
                    ],
                    [
                        this.printer.headerCellForText('Р.бр.'),
                        this.printer.headerCellForText('Датум на книжење'),
                        this.printer.headerCellForText('Број на документ'),
                        this.printer.headerCellForText('Датум на документ'),
                        this.printer.headerCellForNumber('Набавна вредност'),
                        this.printer.headerCellForNumber('Продажна редност'),
                        this.printer.headerCellForNumber('Дневен промет')
                    ],
                    [
                        { text: '1', alignment: 'center' },
                        { text: '2', alignment: 'center' },
                        { text: '3', alignment: 'center' },
                        { text: '4', alignment: 'center' },
                        { text: '5', alignment: 'center' },
                        { text: '6', alignment: 'center' },
                        { text: '7', alignment: 'center' }
                    ],
                    [
                        this.printer.footerCellText('Претходна состојба:', 4),
                        '',
                        '',
                        '',
                        this.printer.footerCellNumber(stranica.pocetnoSaldo.nabavnaVrednost),
                        this.printer.footerCellNumber(stranica.pocetnoSaldo.prodaznaVrednost),
                        this.printer.footerCellNumber(stranica.pocetnoSaldo.dnevenPromet0 +
                            stranica.pocetnoSaldo.dnevenPromet5 +
                            stranica.pocetnoSaldo.dnevenPromet10 +
                            stranica.pocetnoSaldo.dnevenPromet18)
                    ]
                ] as any[],
                dontBreakRows: true
            },
            layout: 'lightHorizontalLines'
        };
        stranica.stavki.map(x => {
                const dnevenPromet = x.dnevenPromet0 + x.dnevenPromet5 + x.dnevenPromet10 + x.dnevenPromet18;
                data.content[0].table.body.push([
                    this.printer.regularCellText(`${x.broj}.`),
                    this.printer.regularCellDate(x.datumNaKnizenje),
                    this.printer.regularCellText(`${(dnevenPromet !== 0 ? 'ДФИ ' : '')}${x.brojNaDokument}`),
                    this.printer.regularCellDate(x.datumNaDokument),
                    this.printer.footerCellNumber(x.nabavnaVrednost),
                    this.printer.footerCellNumber(x.prodaznaVrednost),
                    this.printer.footerCellNumber(dnevenPromet)
                ]);
            }
        );
        data.content[0].table.body.push([
            this.printer.footerCellText('Вкупно:', 4),
            '',
            '',
            '',
            this.printer.footerCellNumber(stranica.zavrsnoSaldo.nabavnaVrednost),
            this.printer.footerCellNumber(stranica.zavrsnoSaldo.prodaznaVrednost),
            this.printer.footerCellNumber(stranica.zavrsnoSaldo.dnevenPromet0 +
                stranica.zavrsnoSaldo.dnevenPromet5 +
                stranica.zavrsnoSaldo.dnevenPromet10 +
                stranica.zavrsnoSaldo.dnevenPromet18)
        ]);
        return data;
    }

    kdfi(kasa: IKdfiKasa, mesec: string) {
        const data = this.printer.getMaterijalenPregled(true);
        const naziv = this.printer.tableHeaderCell([
                this.printer.tableHeaderTitle(`КДФИ-01 за ${kasa.naziv} за месец ${this.getMesec(mesec)}`)
            ],
            15);
        data.content[0] = {
            style: 'fakturaStyle',
            table: {
                widths: [
                    '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto',
                    'auto', 'auto'
                ],
                body: [
                    [naziv, '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
                    [
                        this.printer.headerCellForText('Датум', 1, 2, 'center'),
                        this.printer.headerCellForText('Вкупен промет остварен од македонски производи',
                            1,
                            2,
                            'center'),
                        this.printer.headerCellForText(
                            'Износ на данок од македонски производи прикажан по даночни стапки',
                            3,
                            1,
                            'center'),
                        '',
                        '',
                        this.printer.headerCellForText('Вкупен износ на данок од македонски производи', 1, 2, 'center'),
                        this.printer.headerCellForText(
                            ' Вкупен промет во текoвниот ден со вкалкулиран данок, искажан по даночни стапки',
                            4,
                            1,
                            'center'),
                        '',
                        '',
                        '',
                        this.printer.headerCellForText('Вкупен промет во тековниот ден', 1, 2, 'center'),
                        this.printer.headerCellForText('Износ на данок во тековниот ден по даночни стапки',
                            3,
                            1,
                            'center'),
                        '',
                        '',
                        this.printer.headerCellForText('Вкупен износ на данок во тековниот ден', 1, 2, 'center')
                    ],
                    [
                        '',
                        '',
                        this.printer.headerCellForNumber('5%'),
                        this.printer.headerCellForNumber('10%'),
                        this.printer.headerCellForNumber('18%'),
                        '',
                        this.printer.headerCellForNumber('0%'),
                        this.printer.headerCellForNumber('5%'),
                        this.printer.headerCellForNumber('10%'),
                        this.printer.headerCellForNumber('18%'),
                        '',
                        this.printer.headerCellForNumber('5%'),
                        this.printer.headerCellForNumber('10%'),
                        this.printer.headerCellForNumber('18%'),
                        ''
                    ]
                ] as any[]
            },
            layout: 'lightHorizontalLines'
        };
        kasa.stavki.map(x => {
                data.content[0].table.body.push([
                    this.printer.regularCellDate(x.datum),
                    this.printer.footerCellNumber(0),
                    this.printer.footerCellNumber(0),
                    this.printer.footerCellNumber(0),
                    this.printer.footerCellNumber(0),
                    this.printer.footerCellNumber(0),
                    this.printer.footerCellNumber(0),
                    this.printer.footerCellNumber(0),
                    this.printer.footerCellNumber(0),
                    this.printer.footerCellNumber(x.promet),
                    this.printer.footerCellNumber(x.promet),
                    this.printer.footerCellNumber(0),
                    this.printer.footerCellNumber(0),
                    this.printer.footerCellNumber(x.danok),
                    this.printer.footerCellNumber(x.danok)
                ]);
            }
        );
        data.content[0].table.body.push([
            this.printer.footerCellText('Вкупно:'),
            this.printer.footerCellNumber(0),
            this.printer.footerCellNumber(0),
            this.printer.footerCellNumber(0),
            this.printer.footerCellNumber(0),
            this.printer.footerCellNumber(0),
            this.printer.footerCellNumber(0),
            this.printer.footerCellNumber(0),
            this.printer.footerCellNumber(0),
            this.printer.footerCellNumber(kasa.vkupnoPromet),
            this.printer.footerCellNumber(kasa.vkupnoPromet),
            this.printer.footerCellNumber(0),
            this.printer.footerCellNumber(0),
            this.printer.footerCellNumber(kasa.vkupnoDanok),
            this.printer.footerCellNumber(kasa.vkupnoDanok)
        ]);
        return data;
    }

    getMesec(mesec: string): string {
        switch (mesec) {
        case '1':
            return'Јануари';
        case '2':
            return'Фебруари';
        case '3':
            return'Март';
        case '4':
            return'Април';
        case '5':
            return'Мај';
        case '6':
            return'Јуни';
        case '7':
            return'Јули';
        case '8':
            return'Август';
        case '9':
            return'Септември';
        case '10':
            return'Октомври';
        case '11':
            return'Ноември';
        case '12':
            return'Декември';
        default:
            return'';
        }
    }
}
